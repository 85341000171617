import React from "react";
import { Button, Text } from "@atoms";

const ButtonList = ({ buttons }) => {
  return (
    <section className="mb-10 sm:-mt-10 sm:mb-20">
      {buttons?.length > 0 && (
        <div className="flex flex-wrap justify-center gap-4">
          {buttons?.map((button, i) => {
            return (
              <Button to={button?.url} color="purple" size="md">
                <Text>{button?.text}</Text>
              </Button>
            );
          })}
        </div>
      )}
    </section>
  );
};

ButtonList.defaultProps = {};

export default ButtonList;
